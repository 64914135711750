

























































import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: {
    OPosterList: () =>
      import("@/components/organisms/event/posterSession/o-poster-list.vue"),
  },

  setup(_, { root }) {
    const state = reactive({
      status: "active",
      posterNumber: "",
      title: "",
      sender: "",
    });

    const statusItems = [
      {
        name: root.$tc("layout.misc.active"),
        value: "active",
      },
      {
        name: root.$tc("layout.misc.deleted"),
        value: "deleted",
      },
    ];

    return {
      state,
      statusItems,
    };
  },
});
